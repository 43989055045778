<template>
  <div class="user-active-records">
    <div class="back-container">
      <span @click="onBack">&lt;返回</span>
    </div>
    <div class="list-container">
      <h3 style="text-align: center; margin-bottom: 20px">
        用户: {{ user_name }}
      </h3>
      <!-- <div class="record-item" v-for="(data, index) in records" :key="index">
        <span>类型: {{ data["active_type"] }}</span>
        <span>会员时长: {{ data["active_duration"] }}天</span>
        <span>续费时间: {{ data["active_time"] }}</span>
      </div> -->
      <Table
        :columns="recordColumns"
        :data="records"
        border
        :max-height="tableMaxHeight"
      >
      </Table>
    </div>
  </div>
</template>

<script>
import api from "@/api";
import Store from "@/store";
import { mixinDevice } from "@/util/mixin";

export default {
  mixins: [mixinDevice],
  data() {
    let columnWidth = Store.state.deviceType == "mobile" ? 130 : "auto";
    return {
      user_id: "",
      records: [],
      recordColumns: [
        {
          title: "类型",
          key: "active_type",
          width: columnWidth,
        },
        {
          title: "会员时长",
          key: "active_duration",
          width: columnWidth,
        },
        {
          title: "续费时间",
          key: "active_time",
          width: columnWidth,
        },
      ],
      user_name: "",
    };
  },
  computed: {
    tableMaxHeight() {
      return this.isMobile() ? 540 : 700;
    },
  },
  methods: {
    onBack() {
      this.$router.go(-1);
    },
    loadData() {
      api
        .userActiveRecords(this.user_id)
        .then((res) => {
          this.$log.debug(res);
          if (res.active_records && res.active_records.length > 0) {
            this.records = res.active_records.map((item) => {
              item.user_name = this.user_name;
              return item;
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.user_id = this.$route.query.id;
    this.user_name = this.$route.query.name;
    this.$log.debug("user_id: ", this.user_id);
    this.loadData();
  },
};
</script>
<style lang="less" scoped>
.user-active-records {
  width: 100%;
  padding: 12px;

  .back-container {
    span {
      font-size: 16px;
    }
  }

  .list-container {
    padding: 20px 0;

    .record-item {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;

      &:not:last-child {
        border-bottom: 1px solid #ededed;
      }
    }
  }
}
</style>